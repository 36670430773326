import React, { memo } from 'react';

import { TypographyScale, GridLayout, ThirdPartyLogo } from '@mdb/flora';

import Breadcrumbs from '../breadcrumbs';
import { HeroProps } from './types';
import { heroContainerStyles } from './styles';
import FollowLink from '../follow-link';
import { technologyToLogo } from '../../utils/technology-to-logo';

const Hero: React.FunctionComponent<HeroProps> = memo(
    ({ crumbs, name, description, ctas, topic }) => {
        const logo = technologyToLogo[name];
        return (
            <div sx={heroContainerStyles}>
                <GridLayout sx={{ rowGap: 'inc30' }}>
                    {crumbs && <Breadcrumbs crumbs={crumbs} />}
                    <div sx={{ gridColumn: ['span 6', null, 'span 5'] }}>
                        <div
                            sx={{
                                marginBottom: ['inc20', null, null, 'inc40'],
                                display: 'flex',
                                alignItems: 'center',
                                gap: 'inc30',
                                flexWrap: 'wrap',
                            }}
                        >
                            {!!logo && (
                                <ThirdPartyLogo
                                    // ThirdPartyLogo is an anchor element, so just provide fallback to avoid Lighthouse errors
                                    href={'#'}
                                    alt={logo}
                                    variant={logo}
                                    sx={{
                                        height: ['48px', null, null, '72px'],
                                        width: ['48px', null, null, '72px'],
                                    }}
                                />
                            )}
                            <TypographyScale
                                customElement="h1"
                                variant="heading2"
                                color="mark"
                                // This styling is to accomodate the "Follow" button on topic pages.
                                sx={{
                                    width: topic ? 'min-content' : 'initial',
                                }}
                            >
                                {name}
                            </TypographyScale>
                            {!!topic && <FollowLink topic={topic} />}
                        </div>
                        {!!description && (
                            <TypographyScale variant="body2">
                                {description}
                            </TypographyScale>
                        )}
                    </div>
                    {ctas}
                </GridLayout>
            </div>
        );
    }
);

Hero.displayName = 'Hero';

export default Hero;
